<template>
  <div class="context_whole">
    <van-nav-bar
        style="background-color:rgb(48, 65, 86);position: fixed"
        title="视频会议"
        fixed
    />
<div>

  <div style="width: 100%;position: fixed">
    <div style="margin-top: 20%">
      <div style="color: #cccccc;height: 10%;margin: 5px 0">WebRTC视频会议</div>
    </div>
    <van-cell-group>
      <van-field
          v-model="roomname"
          label="会议号"
          placeholder="请输入会议号"
          required
          clearable
          :label-width="100"
         @input="setroomname"
      />

      <van-field
          v-model="username"
          label="用户昵称"
          placeholder="请输入您的昵称"
          :label-width="100"
          @input="setusername"
      />
    </van-cell-group>
        <div style="width: 80%;margin: 20px auto">
          <van-button  plain type="info" style="margin: 0 auto;width: 100%;" @click="goMeeting">加入会议</van-button>
        </div>
        <van-cell-group>
          <div style="height: 50px;line-height:50px;margin-left: 15px;" >
              开启摄像头
            <div style="float:right;margin-top:10px;margin-right: 15px ">
              <van-switch  style="margin: auto auto;" v-model="cameraChecked"   size="24px"/>
            </div>
          </div>
          <van-cell title="开启麦克风"  >
            <van-switch  style="margin: auto auto;" v-model="microChecked"   size="24px"/>
          </van-cell>
          <van-cell title="会议开启录制"  >
            <van-switch  style="margin: auto auto;" v-model="recordChecked"   size="24px"/>
          </van-cell>

<!--      <van-cell title="标题" value="内容" />-->
    </van-cell-group>
  </div>

</div>
    <van-tabbar v-model="active" class="bottom"  inactive-color="black" active-color="#1988f9">
      <van-tabbar-item  name="meeting" icon="wap-home-o" to="/meeting">视频会议</van-tabbar-item>
      <!--<van-tabbar-item name="video" icon="video" to="/video">视频回放</van-tabbar-item>-->
      <!--<van-tabbar-item name="photo" icon="photo" to="/photo">图片浏览</van-tabbar-item>-->
      <van-tabbar-item  name="user" icon="user-o" to="/user">个人中心</van-tabbar-item>
    </van-tabbar>
  </div>
  </template>
  <script>
    import jsBridge from 'ym-jsbridge'
    export default {
      name: 'TabBar',
      components:{
      },
      data() {
        return {
          cameraChecked:true,
          microChecked:true,
          recordChecked:true,
          active:'meeting',
          username: null,
          roomname: null,
        }
      },
      created(){
		// this.getInput();
    //   this.checkInApp()
      //  this.requestPermission()
      //  this.checkCam()
      },
      mounted(){
        jsBridge.ready(function(){
           jsBridge.requestPermissions(["Camera","Microphone"], function(res) {
              if (res.granted) {
                //alert("摄像头已授权")
                //console.log(res)
              } else {
                //alert("已拒绝，不再询问: " + res.neverAskAgain)
              }
            })
        })
      },
      methods: {
        checkInApp(){
          if (jsBridge.inApp) {
            //alert("你正在APP中使用")
          } else {    
            //alert("不在APP中");
          }
        },
        checkCam(){
          jsBridge.checkCamera(function (res) {
            //alert(JSON.stringify(res));
          });
        },
        requestPermission(){
          jsBridge.requestPermissions(["Camera","Microphone"], function(res) {
            if (res.granted) {
              //alert("摄像头已授权")
              //console.log(res)
            } else {
              //alert("已拒绝，不再询问: " + res.neverAskAgain)
            }
            })
        },
        goMeeting(){
          this.$router.push({
            path: '/final',//url指的是跳转的路径名,需要在router里面配置
            query:{
              roomname: this.roomname,
              username: this.username,
            }
          })  
        },
        setroomname(e){
          this.roomname=e;
          console.log(this.roomname);
          return 
        },
        setusername(e){
			    this.username=e;
			    // console.log(this.password);
        }
      }
    }
  </script>


<style scoped>
.container{
  background-color: #cccccc;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
}



.active_tab img {
  width: 26px;
  height: 26px;
}

/* 自定义选中的颜色 */
.van-tabbar-item--active {
  color: #d81e06;
}


.tui {
  width: 50px;
  height: 50px;
  margin-top: -40px;
  z-index: 100000;
}

[class*=van-hairline]::after {
  border: none !important
}
</style>
